
import { mount } from "@vue/test-utils"
import Vue from "vue"
import { Route } from "vue-router"
const Tabs = () => import("@/components/Tabs.vue")
export default Vue.extend({
	components: {
		Tabs,
	},
	data(): {
		parentModulePath: string
		parentName: string
	} {
		return {
			parentModulePath: this.$router.currentRoute.path.split("/")[1],
			parentName: this.$router.currentRoute.name ? this.$router.currentRoute.name : ""
		}
	},
	watch: {
		$route(to: Route, from: Route) {
			this.parentModulePath = to.path.split("/")[1]
			this.parentName = to.name ? to.name : ""
		},
	},
})
